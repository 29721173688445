<template>
  <div
    class="relative h-full w-full bg-white flex flex-col sm:overflow-auto md:pt-14"
  >
    <div
      class="w-full shadow-md z-50 relative md:fixed md:top-0 md:inset-x-0 h-14 bg-white"
    >
      <navbar />
    </div>
    <div class="w-full duration-200 bg-color-primary">
      <appmain />
    </div>
    <div
      class="w-full bg-color-primary border-t-2 border-[#EEEEEE] bg-white mb-14 md:mb-0"
    >
      <Footer />
    </div>
    <div class="w-full z-100">
      <MobileNavbar />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Navbar from './Navbar.vue'
import MobileNavbar from './MobileNavbar.vue'
import Appmain from './AppMain.vue'
import Footer from '../Member/Footer.vue'
export default defineComponent({
  name: 'Layout',
  components: {
    Navbar,
    Appmain,
    MobileNavbar,
    Footer,
  },
  data() {
    return {
      isOpen: true,
    }
  },
  methods: {},
})
</script>
