<template>
  <section
    class="block md:hidden fixed inset-x-0 bottom-0 z-10 bg-white shadow h-16"
  >
    <div id="tabs" class="flex justify-between">
      <router-link
        :to="'/mentor/'"
        class="w-full justify-center inline-block text-center pt-2 pb-1 focus:text-teal-500 hover:text-teal-500"
        v-slot="{ isExactActive }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="inline-block mb-1"
          :class="
            isExactActive ? 'text-[#009DFF] font-semibold' : 'text-[#383838]'
          "
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.885 1.14214L2.28501 8.34214C2.10782 8.4762 1.99907 8.69746 2.00001 8.91962V21.8796C2.00001 22.2565 2.34313 22.5996 2.72001 22.5996H9.20001C9.57688 22.5996 9.92001 22.2565 9.92001 21.8796V16.3596C9.92001 15.0171 10.9775 13.9596 12.32 13.9596C13.6625 13.9596 14.72 15.0171 14.72 16.3596V21.8796C14.72 22.2565 15.0631 22.5996 15.44 22.5996H21.92C22.2969 22.5996 22.64 22.2565 22.64 21.8796V8.91962C22.64 8.69744 22.5322 8.47618 22.355 8.34214L12.755 1.14214C12.4409 0.942449 12.1785 0.963074 11.885 1.14214ZM12.32 2.61962L21.2 9.27962V21.1596H16.16V16.3596C16.16 14.2446 14.435 12.5196 12.32 12.5196C10.205 12.5196 8.48001 14.2446 8.48001 16.3596V21.1596H3.44001V9.27962L12.32 2.61962Z"
            fill="currentColor"
          />
        </svg>
        <span
          class="block text-xs font-solusi"
          :class="
            isExactActive ? 'text-[#009DFF] font-semibold' : 'text-[#383838]'
          "
          >Beranda</span
        >
      </router-link>
      <router-link
        :to="'/mentor/classroom'"
        class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
        v-slot="{ isExactActive }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="inline-block mb-1"
          :class="
            isExactActive ? 'text-[#009DFF] font-semibold' : 'text-[#383838]'
          "
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M11.9387 8.71398C11.7559 8.71398 11.5965 8.69523 11.4728 8.65679C11.3612 8.62117 11.2656 8.54804 11.2028 8.44867C11.1822 8.41679 11.1634 8.38398 11.1381 8.35492C11.0012 8.20211 10.9712 7.98179 11.0622 7.79898C11.1062 7.71086 11.1662 7.62836 11.2412 7.55243C11.6416 7.15211 11.5647 6.88869 11.4381 6.45369C11.3247 6.06743 11.155 5.4843 11.6612 4.97714C12.1066 4.52526 12.7197 4.2712 13.3169 4.27494C13.84 4.27494 14.3247 4.47182 14.6772 4.82994C15.0344 5.18056 15.2312 5.67462 15.2256 6.21369C15.2247 6.80807 14.9678 7.4015 14.5216 7.84118C14.0097 8.35211 12.7487 8.71398 11.9387 8.71398L11.9387 8.71398ZM13.3112 5.2743C12.9653 5.27336 12.6297 5.41868 12.3709 5.68117C12.3006 5.75149 12.2856 5.78899 12.3981 6.17243C12.5031 6.53149 12.6616 7.0743 12.3747 7.67243C12.8931 7.5843 13.5681 7.37898 13.8166 7.13056C14.0772 6.87368 14.2253 6.539 14.2253 6.209V6.20244C14.2281 6.01494 14.185 5.74683 13.9694 5.53589C13.7575 5.32026 13.4978 5.27434 13.3112 5.27434L13.3112 5.2743Z"
              fill="currentColor"
            />
            <path
              d="M9.50033 12.5C8.8394 12.5 8.21689 12.2919 7.69846 11.8981C6.94846 11.3347 6.50034 10.4375 6.50034 9.5C6.50034 8.56249 6.94846 7.66532 7.6994 7.10094C8.21689 6.70813 8.8394 6.5 9.50033 6.5C10.4472 6.5 11.3435 6.95094 11.9013 7.70845C11.9566 7.77313 12.0025 7.84251 12.0475 7.91282C12.3447 8.39937 12.5004 8.94689 12.5004 9.50001C12.5004 11.1547 11.1541 12.5 9.50035 12.5L9.50033 12.5ZM9.50033 7.50032C9.06065 7.50032 8.64627 7.63813 8.30221 7.89877C7.7997 8.27658 7.4997 8.87564 7.4997 9.50002C7.4997 10.1253 7.79876 10.7235 8.30031 11.1003C8.64625 11.3629 9.05969 11.5007 9.50031 11.5007C10.6038 11.5007 11.5 10.6035 11.5 9.50098C11.5 9.13066 11.3959 8.76411 11.1981 8.4416C11.1822 8.41723 11.1644 8.38441 11.1372 8.35535C11.1259 8.34222 11.1147 8.3291 11.1044 8.31504C10.7359 7.8041 10.1359 7.50036 9.5003 7.50036L9.50033 7.50032Z"
              fill="currentColor"
            />
            <path
              d="M6.50034 12.5C4.84566 12.5 3.50034 11.1547 3.50034 9.5C3.50034 7.84532 4.84566 6.5 6.50034 6.5C7.16127 6.5 7.78378 6.70813 8.30221 7.10187C8.4269 7.19656 8.50002 7.34468 8.50002 7.50126C8.50002 7.65783 8.42596 7.80594 8.30033 7.8997C7.79972 8.27658 7.49972 8.87564 7.49972 9.50002C7.49972 10.1253 7.79878 10.7235 8.30033 11.1003C8.42596 11.195 8.50002 11.3432 8.50002 11.4997C8.50002 11.6563 8.4269 11.8044 8.30221 11.8991C7.78376 12.2919 7.16034 12.5 6.50034 12.5V12.5ZM6.50034 7.50032C5.39689 7.50032 4.50066 8.39751 4.50066 9.5C4.50066 10.6034 5.39785 11.4997 6.50034 11.4997C6.73284 11.4997 6.95878 11.4612 7.16972 11.3862C6.74223 10.8575 6.50034 10.19 6.50034 9.49998C6.50034 8.81092 6.74314 8.14343 7.16972 7.61373C6.95785 7.53873 6.73285 7.50029 6.50034 7.50029V7.50032Z"
              fill="currentColor"
            />
            <path
              d="M14.7251 6.70976C14.6594 6.70976 14.5938 6.69664 14.531 6.67039C14.3435 6.59164 14.2226 6.40694 14.2254 6.20352C14.2282 6.01602 14.1851 5.7479 13.9695 5.53696C13.751 5.31571 13.4857 5.27635 13.2963 5.27541C13.0873 5.2951 12.9082 5.15729 12.8295 4.9698C12.7498 4.78136 12.7929 4.5648 12.9363 4.42135L17.2057 0.147187C17.3004 0.0534384 17.4269 0 17.5601 0C17.6923 0 17.8198 0.0525 17.9135 0.146251L19.3535 1.58625C19.5495 1.78219 19.5495 2.09812 19.3535 2.29312L15.0786 6.56344C14.983 6.65907 14.8555 6.70969 14.7252 6.70969L14.7251 6.70976ZM14.2591 4.51227C14.4119 4.59665 14.5526 4.70259 14.6763 4.82915C14.8001 4.95102 14.9041 5.08883 14.9876 5.23976L18.2924 1.9388L17.5602 1.20661L14.2591 4.51227Z"
              fill="currentColor"
            />
            <path
              d="M19.4997 20.5005H3.50032C2.12219 20.5005 1 19.3792 1 18.0001V4.99983C1 3.6217 2.12126 2.49951 3.50032 2.49951H15.0654C15.267 2.49951 15.4498 2.62139 15.5276 2.80796C15.6054 2.99452 15.5623 3.21015 15.4189 3.35264L14.2601 4.51138C14.413 4.59576 14.5536 4.7017 14.6773 4.82826C14.8011 4.95013 14.9051 5.08794 14.9886 5.23887L17.5873 2.64471C17.6811 2.55284 17.8076 2.50034 17.9398 2.50034H19.4998C20.8779 2.50034 22.0001 3.62159 22.0001 5.00066V18.001C22.0001 19.3782 20.8789 20.5003 19.4998 20.5003L19.4997 20.5005ZM3.50032 3.49983C2.67345 3.49983 2.00032 4.17296 2.00032 4.99983V18.0001C2.00032 18.827 2.67345 19.5002 3.50032 19.5002H19.5006C20.3275 19.5002 21.0006 18.827 21.0006 18.0001L20.9997 4.99983C20.9997 4.17296 20.3266 3.49983 19.4997 3.49983H18.146L15.0776 6.56367C14.9332 6.70805 14.7166 6.7493 14.5301 6.67055C14.3426 6.5918 14.2216 6.4071 14.2245 6.20367C14.2273 6.01617 14.1841 5.74806 13.9685 5.53712C13.7501 5.31587 13.4848 5.27651 13.2954 5.27557C13.0863 5.29526 12.9073 5.15744 12.8285 4.96995C12.7498 4.78152 12.7929 4.56495 12.9363 4.42151L13.8579 3.49995L3.50032 3.49983Z"
              fill="currentColor"
            />
            <path
              d="M15.0006 23.9999H7.9993C7.79773 23.9999 7.61491 23.8781 7.5371 23.6915C7.4593 23.5049 7.50242 23.2893 7.64585 23.1468C7.65804 23.1337 8.9996 21.7602 8.9996 19.9997C8.9996 19.7241 9.22366 19.5 9.49928 19.5H13.4996C13.7752 19.5 13.9993 19.7241 13.9993 19.9997C13.9993 21.7603 15.3408 23.1329 15.354 23.1468C15.4955 23.2902 15.5386 23.5049 15.4608 23.6915C15.384 23.8771 15.2021 23.9999 15.0005 23.9999H15.0006ZM9.02674 22.9996H13.9739C13.5801 22.4034 13.1404 21.5315 13.0279 20.4993H9.97344C9.86 21.5315 9.41938 22.4034 9.02657 22.9996H9.02674Z"
              fill="currentColor"
            />
            <path
              d="M16 23.9999H7.00002C6.7244 23.9999 6.50034 23.7758 6.50034 23.5002C6.50034 23.2246 6.7244 23.0005 7.00002 23.0005H16C16.2756 23.0005 16.4997 23.2246 16.4997 23.5002C16.4997 23.7758 16.2756 23.9999 16 23.9999Z"
              fill="currentColor"
            />
            <path
              d="M20.9997 17.9998H1.49968C1.22406 17.9998 1 17.7758 1 17.5002C1 17.2246 1.22406 17.0005 1.49968 17.0005H20.9997C21.2753 17.0005 21.4994 17.2246 21.4994 17.5002C21.5003 17.7758 21.2762 17.9998 20.9997 17.9998Z"
              fill="currentColor"
            />
            <path
              d="M21.5003 15.4997C20.3818 15.4997 19.794 15.2062 19.2756 14.9475C18.7956 14.7075 18.3803 14.5003 17.499 14.5003C16.6168 14.5003 16.2025 14.7075 15.7225 14.9475C15.2031 15.2062 14.6153 15.4997 13.4969 15.4997C12.3794 15.4997 11.7915 15.2062 11.274 14.9475C10.794 14.7075 10.3797 14.5003 9.49843 14.5003C8.61624 14.5003 8.20188 14.7075 7.72188 14.9475C7.20439 15.2062 6.61656 15.4997 5.49813 15.4997C4.38065 15.4997 3.79281 15.2062 3.27533 14.9475C2.79626 14.7075 2.38188 14.5003 1.49971 14.5003C1.22409 14.5003 1.00003 14.2762 1.00003 14.0006C1.00003 13.725 1.22409 13.5009 1.49971 13.5009C2.6172 13.5009 3.20503 13.7944 3.72252 14.0531C4.20252 14.2922 4.6169 14.5003 5.49813 14.5003C6.38033 14.5003 6.79469 14.2931 7.27469 14.0531C7.79313 13.7934 8.38094 13.5 9.49843 13.5C10.6159 13.5 11.2038 13.7934 11.7212 14.0522C12.2012 14.2922 12.6156 14.5003 13.4969 14.5003C14.379 14.5003 14.7934 14.2931 15.2734 14.0531C15.7928 13.7934 16.3797 13.5 17.4981 13.5C18.6165 13.5 19.2043 13.7934 19.7228 14.0522C20.2028 14.2922 20.6172 14.5003 21.5003 14.5003C21.7759 14.5003 21.9999 14.7244 21.9999 15C21.9999 15.2756 21.7759 15.4997 21.5003 15.4997Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span
          class="block text-xs"
          :class="
            isExactActive ? 'text-[#009DFF] font-semibold' : 'text-[#383838]'
          "
          >Kelas</span
        >
      </router-link>
      <router-link
        :to="'/mentor/profile'"
        class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
        v-slot="{ isExactActive }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="inline-block mb-1"
          :class="
            isExactActive ? 'text-[#009DFF] font-semibold' : 'text-[#383838]'
          "
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22.7129C14.8416 22.7129 17.5658 21.5841 19.5749 19.5751C21.5839 17.5661 22.7126 14.8416 22.7126 12.0002C22.7126 9.15888 21.5839 6.4344 19.5749 4.42536C17.5658 2.41632 14.8413 1.2876 12 1.2876C9.15863 1.2876 6.43415 2.41634 4.42511 4.42536C2.41607 6.43437 1.28735 9.15888 1.28735 12.0002C1.2911 14.8409 2.42079 17.5634 4.42895 19.5715C6.4371 21.5796 9.15959 22.7093 12.0002 22.7131L12 22.7129ZM6.79127 20.069C6.79595 20.0437 6.79783 20.0184 6.79877 19.9931V18.3975C6.79877 16.5394 7.79064 14.822 9.39941 13.8927C11.0091 12.9636 12.991 12.9636 14.6007 13.8927C16.2094 14.8217 17.2013 16.5392 17.2013 18.3975V19.9931V19.9922C17.2023 20.0175 17.2041 20.0437 17.2088 20.0681C15.6582 21.074 13.8488 21.6094 12.0001 21.6094C10.1514 21.6094 8.34203 21.074 6.79139 20.0681L6.79127 20.069ZM9.18939 8.92128C9.18939 7.78409 9.87378 6.75941 10.9247 6.32352C11.9747 5.88852 13.1841 6.12946 13.9876 6.93384C14.792 7.73729 15.032 8.94665 14.597 9.99672C14.162 11.0477 13.1373 11.732 12.0002 11.732C10.4486 11.7311 9.1905 10.473 9.18954 8.9214L9.18939 8.92128ZM12 2.39064C14.6252 2.38876 17.1365 3.46219 18.9507 5.36064C20.7638 7.25909 21.721 9.81744 21.5991 12.4397C21.4772 15.0629 20.2866 17.521 18.3046 19.2422V18.3976C18.3027 17.0485 17.8696 15.736 17.068 14.6514C16.2665 13.5667 15.1387 12.767 13.8496 12.3695C14.8818 11.8164 15.6122 10.8292 15.84 9.68081C16.0678 8.53142 15.7687 7.34081 15.0253 6.43529C14.2809 5.52967 13.1718 5.00561 12.0001 5.00561C10.8283 5.00561 9.71915 5.52967 8.97488 6.43529C8.23143 7.3409 7.93237 8.53154 8.1602 9.68081C8.38801 10.8293 9.11833 11.8164 10.1505 12.3695C8.86146 12.767 7.73372 13.5667 6.93212 14.6514C6.13057 15.7361 5.69744 17.0486 5.69557 18.3976V19.2432V19.2422C3.7137 17.521 2.52301 15.0629 2.40109 12.4397C2.27922 9.81744 3.23641 7.25904 5.04949 5.36064C6.86355 3.46219 9.37501 2.38872 12.0001 2.39064L12 2.39064Z"
            fill="currentColor"
          />
        </svg>
        <span
          class="block text-xs"
          :class="
            isExactActive ? 'text-[#009DFF] font-semibold' : 'text-[#383838]'
          "
          >Profile</span
        >
      </router-link>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavbarMentor',
})
</script>

<style></style>
