
import { defineComponent } from 'vue'
import Navbar from './Navbar.vue'
import MobileNavbar from './MobileNavbar.vue'
import Appmain from './AppMain.vue'
import Footer from '../Member/Footer.vue'
export default defineComponent({
  name: 'Layout',
  components: {
    Navbar,
    Appmain,
    MobileNavbar,
    Footer,
  },
  data() {
    return {
      isOpen: true,
    }
  },
  methods: {},
})
