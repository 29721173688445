<template>
  <div
    class="max-w-6xl h-14 container flex items-center justify-between py-2 px-4 m-auto"
  >
    <img src="/assets/images/mentor/logo-primary.svg" alt="" class="h-7" />
    <div class="hidden md:flex">
      <div class="px-4 self-center" v-for="(route, i) in routes" :key="i">
        <router-link :to="route.path" v-slot="{ isExactActive }">
          <span
            class="hover:text-blue-500 font-solusi"
            :class="
              isExactActive
                ? 'text-[#009DFF] font-semibold'
                : 'text-[#212121] font-medium'
            "
          >
            {{ route.name }}
          </span>
        </router-link>
      </div>
    </div>
    <div class="flex items-center">
      <div class="relative">
        <button
          @click="open = !open"
          class="inline-flex justify-center items-center w-full py-0.5 pl-1 pr-3 font-medium leading-5 transition duration-150 ease-in-out text-white bg-[#00446F] rounded-full focus:outline-none focus:shadow-outline-blue h-10 md:h-9"
          type="button"
        >
          <img
            v-if="
              getProfileData.photo &&
                getProfileData.photo !=
                  'https://platform.sekolahdesain.id/assets/images/navbar/profile2.svg'
            "
            :src="
              getProfileData.photo
                ? getProfileData.photo
                : '/assets/images/navbar/profile.svg'
            "
            class="rounded-full md:mr-3 self-center w-8 h-8 md:w-7 md:h-7"
            alt=""
          />
          <div
            v-else
            class="w-8 h-8 md:w-7 md:h-7 rounded-full text-center font-solusi capitalize align-middle text-white inline-flex justify-center items-center text-sm md:mr-1 font-semibold"
            :style="'background-color: ' + toHex(getProfileData?.name)"
          >
            {{ getInitialName(getProfileData?.name) }}
          </div>
          <span
            class="hidden md:block font-solusi font-semibold text-sm truncate max-w-[125px]"
            >{{ getProfileData.name }}</span
          >
          <svg
            class="w-5 h-5 ml-2 -mr-1"
            :class="
              open
                ? 'duration-500 transform -rotate-180'
                : 'duration-500 transform rotate-0'
            "
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          v-show="open"
          class="dropdown-menus transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
        >
          <div
            class="absolute right-0 w-56 mt-3 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
          >
            <div class="px-4 py-3">
              <p class="text-sm leading-5 font-solusi">Signed in as</p>
              <p
                class="text-sm font-medium leading-5 text-gray-900 truncate font-solusi"
              >
                {{ getProfileData.email }}
              </p>
            </div>
            <div class="py-1">
              <router-link
                @click="logout"
                to="/"
                class="text-[#DE1306] hover:text-red-700 flex justify-end w-full px-4 py-2 text-sm leading-5 cursor-pointer font-solusi font-semibold"
              >
                Keluar
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <a
        class="hidden md:inline-flex text-sm rounded-md px-2 py-1.5 font-semibold md:ml-2 border border-[#088BD5] text-[#088BD5] hover:bg-[#088BD5] hover:text-white shadow hover:shadow-md duration-300"
        href="/"
      >
        Member Area
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'

export default defineComponent({
  name: 'NavbarMentor',
  data() {
    return {
      open: false,
      routes: [
        {
          name: 'Beranda',
          path: '/mentor/',
        },
        {
          name: 'Kelas',
          path: '/mentor/classroom',
        },
        {
          name: 'Profile',
          path: '/mentor/profile',
        },
      ],
    }
  },

  mounted() {
    if (Object.keys(this.getProfileData).length == 0) {
      this.getProfile()
    }
  },

  computed: {
    getProfileData() {
      return this.$store.getters['profileStore/getProfileData']
    },
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch('profileStore/fetchProfileData')
      if (this.getProfileData.role_id != 2) {
        this.$router.push('/')
      }
    },
    async logout() {
      await Auth.logout()
    },

    getInitialName(name: string) {
      if (name) {
        let initials: any = name.split(' ')

        if (initials.length > 1) {
          initials = initials.shift().charAt(0) + initials.pop().charAt(0)
        } else {
          initials = name.substring(0, 1)
        }
        return initials.toUpperCase()
      } else {
        return '@'
      }
    },
    toHex(name: string) {
      if (name) {
        let hash = 0
        if (name?.length === 0) return hash
        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash)
          hash = hash & hash
        }
        let color = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 255
          color += ('00' + value.toString(16)).substr(-2)
        }
        return color
      } else {
        return '#EEEEEE'
      }
    },
  },
})
</script>

<style>
.dropdown:focus-within .dropdown-menus {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>
