
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'

export default defineComponent({
  name: 'NavbarMentor',
  data() {
    return {
      open: false,
      routes: [
        {
          name: 'Beranda',
          path: '/mentor/',
        },
        {
          name: 'Kelas',
          path: '/mentor/classroom',
        },
        {
          name: 'Profile',
          path: '/mentor/profile',
        },
      ],
    }
  },

  mounted() {
    if (Object.keys(this.getProfileData).length == 0) {
      this.getProfile()
    }
  },

  computed: {
    getProfileData() {
      return this.$store.getters['profileStore/getProfileData']
    },
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch('profileStore/fetchProfileData')
      if (this.getProfileData.role_id != 2) {
        this.$router.push('/')
      }
    },
    async logout() {
      await Auth.logout()
    },

    getInitialName(name: string) {
      if (name) {
        let initials: any = name.split(' ')

        if (initials.length > 1) {
          initials = initials.shift().charAt(0) + initials.pop().charAt(0)
        } else {
          initials = name.substring(0, 1)
        }
        return initials.toUpperCase()
      } else {
        return '@'
      }
    },
    toHex(name: string) {
      if (name) {
        let hash = 0
        if (name?.length === 0) return hash
        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash)
          hash = hash & hash
        }
        let color = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 255
          color += ('00' + value.toString(16)).substr(-2)
        }
        return color
      } else {
        return '#EEEEEE'
      }
    },
  },
})
